<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-input v-model="listQuery.teacher_card_id" placeholder="请输入教师卡ID" class="filter-item" style="width: 160px;" clearable />

      <el-select v-model="listQuery.type" placeholder="请输入选择操作类型" class="filter-item" style="width: 160px;" clearable>
        <el-option label="充值" :value="1" />
        <el-option label="回收" :value="2" />
        <el-option label="清零" :value="3" />
        <el-option label="消费" :value="4" />
        <el-option label="退款" :value="5" />
      </el-select>

      <el-input v-model="listQuery.operator" placeholder="请输入操作人员" class="filter-item" style="width: 220px;" clearable />

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="教师卡余额明细.xls" worksheet="教师卡余额明细">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading" style="margin-bottom: 20px;">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.totalAmount }}</div>
            <div class="subtitle">净发放总金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.consumptionAmount }}</div>
            <div class="subtitle">使用总金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.remainingAmount > 0 ? this.remainingAmount : 0 }}</div>
            <div class="subtitle">剩余金额</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="用户ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher_card.user_id }}
        </template>
      </el-table-column>

      <el-table-column label="用户姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher_card.user.name }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher_card.user.phone }}
        </template>
      </el-table-column>

      <el-table-column label="教师卡类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher_card.teacher_card_type.name }}
        </template>
      </el-table-column>

      <el-table-column label="操作类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" type="success">充值</el-tag>
          <el-tag v-else-if="scope.row.type === 2" type="danger">回收</el-tag>
          <el-tag v-else-if="scope.row.type === 3" type="danger">清零</el-tag>
          <el-tag v-else-if="scope.row.type === 4" type="danger">消费</el-tag>
          <el-tag v-else-if="scope.row.type === 5" type="success">退款</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.change }}
        </template>
      </el-table-column>

      <el-table-column label="操作后余额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.after }}
        </template>
      </el-table-column>

      <el-table-column label="操作时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="操作人员" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.operator }}</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        teacher_card_id: "",
        type: "",
        operator: "",
        start_date: "",
        end_date: "",
      },
      dateArr: null,
      json_fields: {
        用户ID: {
          field: "teacher_card.user_id",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        用户姓名: "teacher_card.user.name",
        手机号码: {
          field: "teacher_card.user.phone",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        教师卡类型: "teacher_card.teacher_card_type.name",
        操作类型: {
          field: "type",
          callback: (value) => {
            if (value === 1) return "充值";
            else if (value === 2) return "回收";
            else if (value === 3) return "清零";
            else if (value === 4) return "消费";
            else if (value === 5) return "退款";
            else return "";
          }
        },
        金额: "change",
        操作后余额: "after",
        操作时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        操作人员: "operator"
      },
      exportLoading: false,
      totalAmount: 0,
      consumptionAmount: 0,
      remainingAmount: 0,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_date = newVal[0];
        this.listQuery.end_date = newVal[1];
      } else {
        this.listQuery.start_date = "";
        this.listQuery.end_date = "";
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/teacherCard/balanceLog",
        method: "get",
        params: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.totalAmount = response.data.totalAmount;
        this.consumptionAmount = response.data.consumptionAmount;
        this.remainingAmount = response.data.remainingAmount;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleExport() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/teacherCard/balanceLog",
        method: "get",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.list.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.list.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
